import React from 'react'
import './Menu.css'
import MenuList from '../MenuList/MenuList'

export default function Menu() {
  return (
    <>
    <div className='menu-container h-auto px-10 py-10 sm:px-20 md:px-10 lg:px-38 text-center'>
      <h2 className='text-4xl mb-10 mt-2 font-semibold text-main-blue'>Serwis i opony</h2>
      <MenuList />
    </div>
    </>
  )
}
