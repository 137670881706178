import React from 'react'
import Banner from '../../components/Banner/Banner'
import Slider from '../../components/CompanySlider/CompanySlider'
import Features from '../../components/Features/Features'
import Menu from '../../components/Menu/Menu'
import BlogWidget from '../../components/BlogWidget/BlogWidget'
import Map from '../../components/Map/Map'
import bannerImage from '../../assets/images/mechanic_banner.webp'
import { Helmet } from 'react-helmet';


export default function StronaGlowna() {
  return (
    <div>
      <Helmet>
        <title>Euromaster Bednarscy Rzeszów – Serwis Samochodowy, Serwis Ciężarowy i Opony</title>
      </Helmet>
      <Banner image={bannerImage} title='Nowe opony?' subtitle='Zrobimy to za Ciebie!' buttonText='ZAREZERWUJ TERMIN' buttonLink='https://ebooking.euromaster.pl/pl_PL/PROD/shortBooking?ebk_centreCode=A116'/>
      <Slider />
      <div className="horizontal w-full h-5 bg-gradient-to-r from-main-blue to-main-green m-0"></div>
      <Features />
      <div className="horizontal w-full h-5 bg-gradient-to-r from-main-blue to-main-green m-0"></div>
      <div id='serwis'>
      <Menu />
      </div>
      <div className="horizontal w-full h-5 bg-gradient-to-r from-main-blue to-main-green m-0"></div>
      <BlogWidget />
      <div className="horizontal w-full h-5 bg-gradient-to-r from-main-blue to-main-green m-0"></div>
      <Map />
    </div>
  )
}

