import React from 'react'
import './MenuList.css'
import bieznikowanie from '../../assets/images/bieznikowanie.webp';
import oponyciezarowe from '../../assets/images/opony-ciezarowe.webp';
import poglebianie from '../../assets/images/poglebianie.webp';
import wymiana from '../../assets/images/wymiana-wywazanie.webp';
import mobilny from '../../assets/images/serwis-mobilny.webp';
import naprawa from '../../assets/images/naprawa-ciezarowe.webp';

import MenuButton from '../MenuButton/MenuButton'

const oponyciezaroweData = [
    {
      "title": "OPONY NOWE",
      "desc": "Sprzedaż opon nowych",
      "icon": oponyciezarowe,
      "link": "/opony-ciezarowe/opony-nowe"
    },
    {
      "title": "OPONY BIEŻNIKOWANE NA GORACO",
      "desc": "Opony Michelin",
      "icon": bieznikowanie,
      "link": "/opony-ciezarowe/bieznikowanie"
    },
    {
      "title": "POGŁĘBIANIE BIEŻNIKA",
      "desc": "Pogłębianie bieżnika opony",
      "icon": poglebianie,
      "link": "/opony-ciezarowe/poglebianie"
    },
    {
      "title": "WYMIANA I WYWAŻANIE",
      "desc": "Wymiana opon w rozmiarach: od 17,5\" do 38\"",
      "icon": wymiana,
      "link": "/opony-ciezarowe/wymiana-wywazanie"
    },
    {
      "title": "NAPRAWA OPON",
      "desc": "Wulkanizacja \"na zimno\"",
      "icon": naprawa,
      "link": "/opony-ciezarowe/naprawa-opon-ciezarowe"
    },
    {
      "title": "SERWIS MOBILNY OGUMIENIA",
      "desc": "Usługa mobilna wymiany całego koła z felgą",
      "icon": mobilny,
      "link": "/opony-ciezarowe/mobilny"
    }
  ]

  const MenuCard = ({ title, desc, icon, link }) => (
    <div className='menu-card-container flex flex-col items-center py-10 px-10 bg-white shadow-lg rounded-md border-4 border-main-blue h-80 relative'>
      <div className='image-container w-16 h-16 p-2 bg-main-blue rounded-md flex items-center justify-center'>
        <img src={icon} alt={title} className='h-10 w-10 object-cover'/>
      </div>
      <h3 className='text-xl font-medium mt-4 text-center align-middle'>{title}</h3>
      <p className='text-center mt-2'>{desc}</p>
      <MenuButton text='WYBIERZ' href={link} className='main-button menu-button' />
    </div>
  );
  
  export default function MenuList() {
    return (
      <div className='menu-container grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-6 py-16 md:px-24 sm:px-20 px-10 xl:px-32'>
        {oponyciezaroweData.map((item, index) => (
          <MenuCard 
            key={index}
            title={item.title}
            desc={item.desc}
            icon={item.icon}
            link={item.link}
          />
        ))}
      </div>
    )
  }
