import React from 'react'
import { Helmet } from 'react-helmet';
import MenuList from '../../components/MenuList/MenuList'

export default function Serwis() {
  return (
    <div>
      <Helmet>
        <title>Serwis Samochodowy i Serwis Ciężarowy - Euromaster Bednarscy</title>
      </Helmet>
      <div className='menu-container h-auto px-10 py-10 sm:px-20 md:px-10 lg:px-38 text-center'>
      <h2 className='text-4xl mb-10 font-semibold text-main-blue'>Serwis i opony</h2>
      <MenuList />
    </div>
    </div>
  )
}
