import React from 'react';
import PropTypes from 'prop-types';

export default function MenuButton({ text, href = '#', onClick = () => {}, className = '' }) {
  return (
    <a
      href={href}
      onClick={onClick}
      className={`main-button px-10 py-3 bg-main-blue text-white font-bold text-md rounded-md transform transition-transform duration-300 ease-in-out ${className}`}
    >
      {text}
    </a>
  );
}

MenuButton.propTypes = {
  text: PropTypes.string.isRequired,
  href: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
};