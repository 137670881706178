import React from 'react';
import kompleksowosc from '../../assets/images/kompleksowosc.webp';
import doswiadczenie from '../../assets/images/doswiadczenie.webp';
import szybkosc from '../../assets/images/szybkosc.webp';
import './FeaturesList.css';

const featuresData = [
  {
    "title": "Kompleksowość usług",
    "icon": kompleksowosc,
    "description": "Oferujemy zarówno sprzedaż nowych opon, jak i ich profesjonalną wymianę w jednym miejscu."
  },
  {
    "title": "Doświadczenie i profesjonalizm",
    "icon": doswiadczenie,
    "description": "Dzięki wykwalifikowanemu personelowi, zapewniamy wysoką jakość świadczonych usług."
  },
  {
    "title": "Szybka i efektywna obsługa",
    "icon": szybkosc,
    "description": "Gwarantujemy błyskawiczną realizację usług, co pozwala klientom zaoszczędzić cenny czas."
  }
];

const FeatureCard = ({ img, title, description }) => (
  <div className='features-card flex flex-col items-center justify-center text-center p-6'>
    <img src={img} alt={title} className='h-16 w-16 aspect-square mx-auto'/>
    <h1 className='text-3xl m-6 font-medium'>{title}</h1>
    <p className='text-lg'>{description}</p>
  </div>
);

export default function FeaturesList() {
  return (
    <div className='features-container grid grid-cols-1 md:grid-cols-1 lg:grid-cols-3 gap-10'>
      {featuresData.map((feature, index) => (
        <FeatureCard 
          key={index}
          img={feature.icon}
          title={feature.title}
          description={feature.description}
        />
      ))}
    </div>
  );
}