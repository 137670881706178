import React, { useState, useEffect } from 'react';
import './App.css';
import Header from './components/Header/Header';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import StronaGlowna from './pages/StronaGlowna/StronaGlowna';
import Serwis from './pages/Serwis/Serwis';
import Kontakt from './pages/Kontakt/Kontakt';
import Blog from './pages/Blog/Blog';
import BlogPost from './pages/BlogPost/BlogPost';

import OponyCiezarowe from './pages/OponyCiezarowe/OponyCiezarowe';
import Bieznikowanie from './pages/OponyCiezarowe/Bieznikowanie';
import Mobilny from './pages/OponyCiezarowe/Mobilny';
import NaprawaCiezarowe from './pages/OponyCiezarowe/Naprawa';
import Poglebianie from './pages/OponyCiezarowe/Poglebianie';
import WymianaCiezarowe from './pages/OponyCiezarowe/Wymiana';
import OponyNoweCiezarowe from './pages/OponyCiezarowe/OponyNowe';

import OponyOsobowe from './pages/OponyOsobowe/OponyOsobowe';
import CzujnikiTpms from './pages/OponyOsobowe/CzujnikiTpms';
import Felgi from './pages/OponyOsobowe/Felgi';
import HotelOpon from './pages/OponyOsobowe/HotelOpon';
import NaprawaOpon from './pages/OponyOsobowe/NaprawaOpon';
import OponyNowe from './pages/OponyOsobowe/OponyNowe';
import WymianaOpon from './pages/OponyOsobowe/WymianaOpon';
import Opony from './pages/Opony/Opony';

import MechanikaCiezarowa from './pages/MechanikaCiezarowa/MechanikaCiezarowa';
import AkumulatorCiezarowe from './pages/MechanikaCiezarowa/Akumulator';
import HamulceCiezarowe from './pages/MechanikaCiezarowa/Hamulce';
import OlejCiezarowe from './pages/MechanikaCiezarowa/Olej';
import WycieraczkiCiezarowe from './pages/MechanikaCiezarowa/Wycieraczki';
import ZawieszenieCiezarowe from './pages/MechanikaCiezarowa/Zawieszenie';

import MechanikaOsobowa from './pages/MechanikaOsobowa/MechanikaOsobowa';
import AkumulatorOsobowe from './pages/MechanikaOsobowa/Akumulator';
import HamulceOsobowe from './pages/MechanikaOsobowa/Hamulce';
import OlejOsobowe from './pages/MechanikaOsobowa/Olej';
import WycieraczkiOsobowe from './pages/MechanikaOsobowa/Wycieraczki';
import ZawieszenieOsobowe from './pages/MechanikaOsobowa/Zawieszenie';
import Footer from './components/Footer/Footer';

const App = () => {
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 300) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <Router>
      <Header />
      <main className='main-content'>
        <Routes>
          <Route path="/" element={<StronaGlowna />} />
          <Route path="/serwis" element={<Serwis />} />
          <Route path="/kontakt" element={<Kontakt />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/:id" element={<BlogPost />} />

          <Route path="/opony-ciezarowe" element={<OponyCiezarowe />} />
          <Route path="/opony-ciezarowe/bieznikowanie" element={<Bieznikowanie />} />
          <Route path="/opony-ciezarowe/mobilny" element={<Mobilny />} />
          <Route path="/opony-ciezarowe/naprawa-opon-ciezarowe" element={<NaprawaCiezarowe />} />
          <Route path="/opony-ciezarowe/poglebianie" element={<Poglebianie />} />
          <Route path="/opony-ciezarowe/wymiana-wywazanie" element={<WymianaCiezarowe />} />
          <Route path="/opony-ciezarowe/opony-nowe" element={<OponyNoweCiezarowe />} />

          <Route path="/opony-osobowe" element={<OponyOsobowe />} />
          <Route path="/opony-osobowe/czujniki-tpms" element={<CzujnikiTpms />} />
          <Route path="/opony-osobowe/felgi" element={<Felgi />} />
          <Route path="/opony-osobowe/hotel-opon" element={<HotelOpon />} />
          <Route path="/opony-osobowe/naprawa-opon" element={<NaprawaOpon />} />
          <Route path="/opony-osobowe/opony-nowe" element={<OponyNowe />} />
          <Route path="/opony-osobowe/wymiana-opon" element={<WymianaOpon />} />
          <Route path="/opony" element={<Opony />} />

          <Route path="/mechanika-ciezarowa" element={<MechanikaCiezarowa />} />
          <Route path="/mechanika-ciezarowa/akumulator" element={<AkumulatorCiezarowe />} />
          <Route path="/mechanika-ciezarowa/hamulce" element={<HamulceCiezarowe />} />
          <Route path="/mechanika-ciezarowa/olej" element={<OlejCiezarowe />} />
          <Route path="/mechanika-ciezarowa/wycieraczki" element={<WycieraczkiCiezarowe />} />
          <Route path="/mechanika-ciezarowa/zawieszenie" element={<ZawieszenieCiezarowe />} />

          <Route path="/mechanika-osobowa" element={<MechanikaOsobowa />} />
          <Route path="/mechanika-osobowa/akumulator" element={<AkumulatorOsobowe />} />
          <Route path="/mechanika-osobowa/hamulce" element={<HamulceOsobowe />} />
          <Route path="/mechanika-osobowa/olej" element={<OlejOsobowe />} />
          <Route path="/mechanika-osobowa/wycieraczki" element={<WycieraczkiOsobowe />} />
          <Route path="/mechanika-osobowa/zawieszenie" element={<ZawieszenieOsobowe />} />
        </Routes>
      </main>
      <Footer />
      {showButton && (
        <button onClick={scrollToTop} className="back-to-top">
          ↑
        </button>
      )}
    </Router>
  );
}

export default App;