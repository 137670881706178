import React from 'react'
import OponyList from '../../components/MenuList/OponyOsoboweList'
import oponyosobowe from '../../assets/images/osobowe-bg.webp'
import Banner from '../../components/Banner/Banner'
import { Helmet } from 'react-helmet';

export default function OponyOsobowe() {
  return (
    <div>
      <Helmet>
        <title>Opony do samochodów osobowych, dostawczych i SUV - Euromaster Bednarscy</title>
      </Helmet>
      <Banner image={oponyosobowe} title='Opony do samochodów osobowych, SUV i dostawczych' buttonText='ZAREZERWUJ TERMIN' buttonLink='https://ebooking.euromaster.pl/pl_PL/PROD/shortBooking?ebk_centreCode=A116'/>
      <OponyList />
    </div>
  )
}
