import React from 'react';
import './ReusableBanner.css';

const ReusableBanner = ({ title, backgroundImage }) => {
  return (
    <div className='banner'>
      <img src={backgroundImage} alt='Banner' className='banner-image w-full h-full object-cover object-middle' />
      <div className='banner-content pl-4 pr-4 md:pl-20 text-center md:text-left'>
        <h1 className='banner-title text-5xl sm:text-5xl text-center md:text-6xl md:text-left font-normal text-white'>{title}</h1>
      </div>
    </div>
  );
};

export default ReusableBanner;