import React from 'react';
import barum from '../../assets/images/barum.svg';
import bridgestone from '../../assets/images/bridgestone.svg';
import continental from '../../assets/images/continental.svg';
import dunlop from '../../assets/images/dunlop.svg';
import fulda from '../../assets/images/fulda.svg';
import goodrich from '../../assets/images/goodrich.svg';
import goodyear from '../../assets/images/goodyear.svg';
import kleber from '../../assets/images/kleber.svg';
import pirelli from '../../assets/images/pirelli.svg';
import kormoran from '../../assets/images/kormoran.svg';
import michelin from '../../assets/images/michelin.svg';
import './CompanySlider.css';

export default function CompanySlider() {
  const logos = [
    { src: barum, alt: 'Barum' },
    { src: bridgestone, alt: 'Bridgestone' },
    { src: continental, alt: 'Continental' },
    { src: dunlop, alt: 'Dunlop'  },
    { src: fulda, alt: 'Fulda' },
    { src: goodrich, alt: 'Goodrich' },
    { src: goodyear, alt: 'Goodyear' },
    { src: kleber, alt: 'Kleber' },
    { src: kormoran, alt: 'Kormoran' },
    { src: michelin, alt: 'Michelin' },
    { src: pirelli, alt: 'Pirelli' },
  ];

  return (
    <div className="slider-container">
      <div className="slider py-1">
        <ul className="logos">
          {logos.map((logo, index) => (
            <li key={index}>
              <img src={logo.src} alt={logo.alt} className="logo-img" loading='lazy'/>
            </li>
          ))}
          {logos.map((logo, index) => (
            <li key={index + logos.length}>
              <img src={logo.src} alt={logo.alt} className="logo-img" loading='lazy'/>
            </li>
          ))}
          {logos.map((logo, index) => (
            <li key={index + logos.length}>
              <img src={logo.src} alt={logo.alt} className="logo-img" loading='lazy'/>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}