import React from 'react'
import Banner from '../../components/Banner/Banner'
import nowebg from '../../assets/images/nowe-bg.webp'
import barum from '../../assets/images/barum.svg';
import bridgestone from '../../assets/images/bridgestone.svg';
import continental from '../../assets/images/continental.svg';
import dunlop from '../../assets/images/dunlop.svg';
import fulda from '../../assets/images/fulda.svg';
import goodrich from '../../assets/images/goodrich.svg';
import goodyear from '../../assets/images/goodyear.svg';
import kleber from '../../assets/images/kleber.svg';
import pirelli from '../../assets/images/pirelli.svg';
import kormoran from '../../assets/images/kormoran.svg';
import michelin from '../../assets/images/michelin.svg';
import tigar from '../../assets/images/tigar.svg';
import { Helmet } from 'react-helmet';
import './OponyCiezarowe.css'


export default function OponyNowe() {

  const logos = [
    { src: michelin, alt: 'Michelin' },
    { src: goodrich, alt: 'Goodrich' },
    { src: kleber, alt: 'Kleber' },
    { src: kormoran, alt: 'Kormoran' },
    { src: tigar, alt: 'Tigar' },
    { src: fulda, alt: 'Fulda' },
    { src: goodyear, alt: 'Goodyear' },
    { src: dunlop, alt: 'Dunlop'  },
    { src: continental, alt: 'Continental' },
    { src: bridgestone, alt: 'Bridgestone' },
    { src: pirelli, alt: 'Pirelli' },
    { src: barum, alt: 'Barum' },
  ];
  return (
    <>
    <Helmet>
      <title>Opony nowe do samochodów ciężarowych - Euromaster Bednarscy</title>
    </Helmet>
    <Banner image={nowebg} title='Opony nowe' buttonText="ZAREZERWUJ TERMIN" buttonLink="https://ebooking.euromaster.pl/pl_PL/PROD/shortBooking?ebk_centreCode=A116" />

    <div className='md:px-20 px-8 py-20 flex flex-col items-center'>
      <h1 className='text-main-blue font-bold text-4xl items-center text-center'>​Marki, z którymi współpracuje Euromaster.</h1>
      <div className='grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-4 mt-10 items-center justify-center'>
        {logos.map((logo, index) => (
          <div key={index} className='flex items-center justify-center h-40'>
            <img src={logo.src} alt={logo.alt} className='h-full object-contain' />
          </div>
        ))}
      </div>
    </div>
    </>
  )
}