import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { supabase } from '../../supabaseClient';
import { Helmet } from'react-helmet';
import './BlogPost.css';
import ReusableBanner from '../../components/ReusableBanner/ReusableBanner';

const BlogPost = () => {
  const { id } = useParams();
  const [blog, setBlog] = useState(null);
  const [likes, setLikes] = useState(0);
  const [hasLiked, setHasLiked] = useState(false);

  useEffect(() => {
  const fetchBlog = async () => {
    const { data, error } = await supabase
      .from('blogs')
      .select('*')
      .eq('id', id)
      .single();

    if (error) {
      console.error('Error fetching blog:', error);
    } else {
      setBlog(data);
      setLikes(data.likes || 0); // Initialize likes count
    }
  };

  fetchBlog();

  // Check if the user has already liked this post in this session
  const likedPosts = JSON.parse(localStorage.getItem('likedPosts')) || [];
  if (likedPosts.includes(id)) {
    setHasLiked(true);
  }

  // Subscribe to real-time updates
  const subscription = supabase
    .channel('public:blogs')
    .on('postgres_changes', { event: 'UPDATE', schema: 'public', table: 'blogs', filter: `id=eq.${id}` }, payload => {
      setLikes(payload.new.likes);
    })
    .subscribe();

  return () => {
    supabase.removeChannel(subscription);
  };
}, [id]);

  const handleLike = async () => {
    if (hasLiked) return;

    const { data, error } = await supabase
      .from('blogs')
      .update({ likes: likes + 1 })
      .eq('id', id)
      .single();

    if (error) {
      console.error('Error updating likes:', error);
    } else if (data) {
      setLikes(data.likes);
      setHasLiked(true);

      // Store the liked post in local storage
      const likedPosts = JSON.parse(localStorage.getItem('likedPosts')) || [];
      likedPosts.push(id);
      localStorage.setItem('likedPosts', JSON.stringify(likedPosts));
    } else {
      console.error('Error: No data returned from the update operation.');
    }
  };

  if (!blog) {
    return <div className='p-5'>Ładowanie zawartości...</div>;
  }

  return (
    <>
    <Helmet>
      <title>{blog.title} - Euromaster Bednarscy</title>
      <meta name="description" content={blog.description} />
      <meta property="og:title" content={blog.title} />
      <meta property="og:description" content={blog.description} />
      <meta property="og:image" content={blog.image_url} />
      <meta property="og:type" content="article" />
      <meta property="og:site_name" content="Euromaster Bednarscy" />

    </Helmet>
    <ReusableBanner title={blog.title} subtitle={blog.subtitle} backgroundImage={blog.image_url} />
    <div className="blog-post">
      <div className='blog-content' dangerouslySetInnerHTML={{ __html: blog.content }} />

      <div className="author-info">
          <p>Autor: Euromaster Bednarscy</p>
          <p className='date'>{new Date(blog.created_at).toLocaleDateString('pl-PL', { day: '2-digit', month: '2-digit', year: 'numeric' })}</p>
      </div>

      <div className="like-section">
          <button onClick={handleLike} className="heart-button" disabled={hasLiked}>💚</button>
          <span>{likes} Polubień</span>
      </div>
    </div>
    </>
  );
};

export default BlogPost;