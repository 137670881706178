import React from 'react'
import lokalizacja from '../../assets/images/lokalizacja.webp'
import mail from '../../assets/images/mail-kontakt.webp'
import telefon from '../../assets/images/telefon-kontakt.webp'

export default function Kontakt() {
  return (
    <>
      <div className="contact-container flex flex-col justify-center items-center my-16 mb-20">
        <div className="contact-info flex flex-col items-center px-72 text-center">
          <h1 className='font-bold text-main-blue text-4xl mb-8'>SKONTAKTUJ SIĘ Z NAMI!</h1>
          <p className='text-lg'>Pomożemy Ci w doborze opon, oraz odpowiemy na wszystkie pytania dotyczące serwisu.</p>
        </div>
        <div className="contact-methods flex flex-col sm:flex-col md:flex-col lg:flex-row xl:flex-row justify-center gap-20 mt-16">
          <div className="contact-method flex flex-col align-middle items-center">
            <div className='image-container w-16 h-16 p-2 bg-main-blue rounded-full flex items-center justify-center'>
              <img src={lokalizacja} alt="Lokalizacja" />
            </div>
            <div className="method-details flex flex-col gap-2 mt-4 align-middle justify-center items-center">
              <h3 className='font-bold text-xl'>Odwiedź</h3>
              <p>Zajrzyj do naszego serwisu!</p>
              <p>ul. Łąkowa 11</p>
              <p>35-212 Rzeszów</p>
            </div>
          </div>
          <div className="contact-method flex flex-col align-middle items-center">
            <div className='image-container w-16 h-16 p-2 bg-main-blue rounded-full flex items-center justify-center'>
              <img src={mail} alt="Email" />
            </div>
            <div className="method-details flex flex-col gap-2 mt-4 align-middle justify-center items-center">
              <h3 className='font-bold text-xl'>Napisz</h3>
              <p>Nasi pracownicy z chęcią Ci pomogą!</p>
              <a href="mailto:bednarscy.rzeszow@euromaster.pl" className='flex items-center'>
                bednarscy.rzeszow@euromaster.pl
              </a>
            </div>
          </div>
          <div className="contact-method flex flex-col align-middle items-center">
            <div className='image-container w-16 h-16 p-2 bg-main-blue rounded-full flex items-center justify-center'>
              <img src={telefon} alt="Telefon" />
            </div>
            <div className="method-details flex flex-col gap-2 mt-4 align-middle justify-center items-center">
              <h3 className='font-bold text-xl'>Zadzwoń</h3>
              <p className=''>Wolisz porozmawiać?</p>
              <p className=''>Zapraszamy do kontaktu!</p>
              <a href="tel:+48694380000" className='flex items-center'>
                694 380 000
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
