import React from 'react'
import './Banner.css'
import MainButton from '../MainButton/MainButton'

const Banner = ({ image, title, subtitle, buttonText, buttonLink }) => {
  return (
    <div className='banner'>
        <img src={image} alt='Banner' className='banner-image w-full h-full object-cover object-middle' fetchPriority='high' />
        <div className='banner-content py-x md:pl-20 text-center md:text-left'>
            <h1 className='banner-title text-5xl sm:text-6xl text-center md:text-7xl md:text-left font-normal text-white'>{title}</h1>
            <h1 className='banner-subtitle text-center sm:text-6xl text-5xl md:text-7xl md:text-left  font-bold text-white'>{subtitle}</h1>
            <div className='mt-8 md:mt-8 sm:mt-8 flex justify-center md:justify-start'>
                <MainButton text={buttonText} href={buttonLink} className='main-button' />
            </div>
        </div>
    </div>
  );
};

export default Banner;