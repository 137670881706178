import React, { useEffect, useState } from 'react';
import { supabase } from '../../supabaseClient';
import BlogCard from '../../components/BlogCard/BlogCard';
import './BlogWidget.css';

const BlogWidget = () => {
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    const fetchBlogs = async () => {
      const { data, error } = await supabase
        .from('blogs')
        .select('*')
        .not('image_url', 'is', null)
        .order('created_at', { ascending: false });

      if (error) {
        console.error('Error fetching blogs:', error);
      } else {
        setBlogs(data);
      }
    };

    fetchBlogs();
  }, []);

  return (
  <>
      <div className="blog-widget py-10 grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 gap-2 justify-items-center">
      {blogs.map((blog) => (
        <BlogCard key={blog.id} blog={blog} />
      ))}
    </div>
  </>
);
};

export default BlogWidget;