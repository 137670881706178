import React from 'react'
import './MenuList.css'
import zawieszenie from '../../assets/images/zawieszenie.webp';
import olej from '../../assets/images/olej.webp';
import hamulce from '../../assets/images/hamulce.webp';
import akumulator from '../../assets/images/akumulator.webp';
import wycieraczki from '../../assets/images/wycieraczki.webp';
import MenuButton from '../MenuButton/MenuButton'

const mechanikaosobowaData = [
    {
      "title": "NAPRAWA ZAWIESZENIA",
      "desc": "Wymiana sprężyn i amortyzatorów",
      "icon": zawieszenie,
      "link": "/mechanika-osobowa/zawieszenie"
    },
    {
      "title": "SERWIS OLEJOWY",
      "desc": "Wymiana filtrów i płynów eksploatacyjnych",
      "icon": olej,
      "link": "/mechanika-osobowa/olej"
    },
    {
      "title": "UKŁAD HAMULCOWY",
      "desc": "Wymiana klocków i tarcz hamulcowych",
      "icon": hamulce,
      "link": "/mechanika-osobowa/hamulce"
    },
    {
      "title": "AKUMULATORY",
      "icon": akumulator,
      "link": "/mechanika-osobowa/akumulator"
    },
    {
      "title": "WYCIERACZKI",
      "icon": wycieraczki,
      "link": "/mechanika-osobowa/wycieraczki"
    },
    {
      "title": "INNE",
      "icon": wycieraczki,
      "link": "/mechanika-osobowa/wycieraczki"
    }
  ]

  const MenuCard = ({ title, desc, icon, link }) => (
    <div className='menu-card-container flex flex-col items-center py-10 px-10 bg-white shadow-lg rounded-md border-4 border-main-blue h-80 relative'>
      <div className='image-container w-16 h-16 p-2 bg-main-blue rounded-md flex items-center justify-center'>
        <img src={icon} alt={title} className='h-10 w-10 object-cover'/>
      </div>
      <h3 className='text-xl font-medium mt-4 text-center align-middle'>{title}</h3>
      <p className='text-center mt-2'>{desc}</p>
      <MenuButton text='WYBIERZ' href={link} className='main-button menu-button' />
    </div>
  );
  
  export default function MenuList() {
    return (
      <div className='menu-container grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-6 py-16 md:px-24 sm:px-20 px-10 xl:px-32'>
        {mechanikaosobowaData.map((item, index) => (
          <MenuCard 
            key={index}
            title={item.title}
            desc={item.desc}
            icon={item.icon}
            link={item.link}
          />
        ))}
      </div>
    )
  }
