import React from 'react'
import Banner from '../../components/Banner/Banner'
import mobilnybg from '../../assets/images/mobilny-bg.webp'
import { Helmet } from'react-helmet';
import './OponyCiezarowe.css'

export default function Mobilny() {
  return (
    <>
    <Helmet>
      <title>Serwis mobilny TIR - Wymiana koła ciężarowego na Drodze - Euromaster Bednarscy</title>
    </Helmet>
    <Banner image={mobilnybg} title="Serwis mobilny" buttonText="ZADZWOŃ" buttonLink="tel:+48694370000" />

    <div className='onepage-content md:px-20 px-8 py-10'>
     
     <h1>​Mobilny Serwis TIR - Wymiana Koła Ciężarowego na Drodze</h1>
      
      <p>​Oferujemy usługę Mobilnego Serwisu TIR, specjalizującą się w usuwaniu awarii kół ciężarowych na drodze. Nasze usługi obejmują zarówno pojazdy ciężarowe, jak i naczepy.</p>
      
      <h2>​Zakres Usług:</h2>

      <ul>
        <li>
        ​Szybka i profesjonalna wymiana całego koła z felgą bezpośrednio w miejscu awarii.
        </li>
        <li>
        Możliwość wymiany koła na oponie używanej lub nowej, w zależności od preferencji i potrzeb klienta.
        </li>
        <li>
        Serwis dostępny 24/7, zapewniając natychmiastową pomoc niezależnie od pory dnia czy nocy.
        </li>
      </ul>

      <h2>​Dlaczego warto skorzystać z naszych usług?</h2>

      <ul>
        <li>
        <b>​Szybkość i wygoda:</b> Dojeżdżamy na miejsce awarii, minimalizując czas postoju i związane z tym straty.
        </li>
        <li>
        <b>Profesjonalizm:</b> Nasz zespół to wykwalifikowani specjaliści z doświadczeniem w serwisowaniu opon ciężarowych.
        </li>
        <li>
        <b>Elastyczność:</b> Oferujemy możliwość wyboru między oponami nowymi a używanymi, dostosowując się do indywidualnych potrzeb klienta.
        </li>
      </ul>

      <div className='onepage-footer px-10'>
        <h2>​Skontaktuj się z nami, aby uzyskać szybką i niezawodną pomoc na drodze. Twój komfort i bezpieczeństwo są dla nas priorytetem.</h2>
      </div>

    </div>
    </>
  )
}