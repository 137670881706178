import React from 'react'
import Banner from '../../components/Banner/Banner'
import zawieszeniebg from '../../assets/images/zawieszenie-bg.webp'
import { Helmet } from 'react-helmet';
import './MechanikaOsobowa.css'

export default function Zawieszenie() {
  return (
    <>
    <Helmet>
      <title>Naprawa zawieszenia samochodów osobowych, SUV i dostawczych - Euromaster Bednarscy</title>
    </Helmet>
    <Banner image={zawieszeniebg} title="Usługa naprawy zawieszenia" buttonText="ZAREZERWUJ TERMIN" buttonLink="https://ebooking.euromaster.pl/pl_PL/PROD/shortBooking?ebk_centreCode=A116" />
    <div className='onepage-content md:px-20 px-8 py-10'>
      <h1>Usługa naprawy zawieszenia w Euromaster Bednarscy</h1>
      <p>Głównym zadaniem amortyzatorów jest tłumienie drgań przenoszonych na nadwozie. Odpowiadają one również za szereg bardziej złożonych procesów wpływających na bezpieczeństwo i komfort w czasie jazdy.</p>

      <h2>Jak amortyzatory wpływają na komfort i bezpieczeństwo jazdy?</h2>
      <p>Amortyzatory zapewniają prawidłowy kontakt kół z nawierzchnią, co jest kluczowe dla skutecznego przenoszenia sił napędu, hamowania i kierowania pojazdem. Zbyt słabe tłumienie negatywnie wpływa na komfort podróżowania, powodując nadmierne drgania, które przenoszą się na reflektory i mogą oślepiać innych kierowców, co prowadzi do niebezpiecznych sytuacji na drodze.</p>
      <p>Nadmierne tłumienie również jest niekorzystne, ponieważ obniża przyczepność kół. Nowoczesne amortyzatory są zaprojektowane tak, aby równoważyć wymagania komfortu i bezpieczeństwa jazdy.</p>
      <p>Zużyty w 50% amortyzator wydłuża drogę hamowania o 2 metry przy prędkości 50 km/h. Amortyzatory wpływają także na działanie systemu ABS – gdy tracą sprężystość, sterownik ABS może odczytywać to jako brak przyczepności i ograniczać intensywność pracy hamulca.</p>
      <p>Złe działanie amortyzatorów zwiększa ryzyko aquaplaningu na mokrej nawierzchni, gdy koła podskakują na wodzie jak na nierównościach, co prowadzi do gwałtownego spadku przyczepności.</p>
      <p>Stan amortyzatorów należy sprawdzać raz w roku lub co 20 000 kilometrów. Specjaliści zalecają ich wymianę co 80 000-100 000 kilometrów. Regularna kontrola z pewnością pomoże zwiększyć ich żywotność.</p>
      <h2>​Jakie są oznaki zużycia amortyzatorów?</h2>
      <p>​Najważniejsze objawy wskazujące na konieczność pilnego serwisu amortyzatorów to:</p>
      <ul>
        <li>
        ​Wydłużona droga hamowania
        </li>
        <li>
        Problem z nierównym prowadzeniem pojazdu na zakrętach
        </li>
        <li>
        Podskakiwanie kół w czasie jazdy
        </li>
        <li>
        Wibracje odczuwalne w układzie kierowniczym
        </li>
        <li>
        Kołysanie nadwozia pojazdu
        </li>
        <li>
        „Dobijanie” zawieszenia
        </li>
        <li>
        Efekt nurkowania podczas hamowania
        </li>
        <li>
        Brak odporności na boczne podmuchy wiatru
        </li>
        <li>
        Charakterystyczne "przestawianie" pojazdu podczas pokonywania torów kolejowych czy poprzecznych uskoków
        </li> 
        <li>
        Nierównomierne zużycie opon
        </li> 
        <li>
        Obecność oleju na amortyzatorze
        </li> 
        <li>
        Przyspieszone zużycie elementów układu kierowniczego
        </li> 
      </ul>
      <p>​Amortyzatory nigdy nie są w 100% sprawne. Ich wydajność mierzy się przez test przylegania koła do podłoża. Nowy amortyzator osiąga wynik na poziomie 70%, co oznacza bardzo dobry stan. Wymianę amortyzatorów należy rozważyć przy wyniku poniżej 40%.</p>
      <h2>​Wymiana sprężyn amortyzatora</h2>
      <p>
      ​​Wymiana amortyzatorów często wiąże się również z koniecznością wymiany sprężyn amortyzatora. Sprężyny współpracują z amortyzatorami, zapewniając właściwe tłumienie drgań oraz stabilność pojazdu. Uszkodzone lub zużyte sprężyny mogą prowadzić do nierównego osiadania pojazdu, co negatywnie wpływa na komfort i bezpieczeństwo jazdy. Regularna kontrola stanu sprężyn i ich wymiana w razie potrzeby jest kluczowa dla utrzymania pełnej sprawności układu zawieszenia. Zaleca się wymianę sprężyn parą, aby zapewnić równomierne obciążenie i prawidłowe działanie zawieszenia.
      </p>
      <p>
      Wymiana sprężyn bez amortyzatorów jest możliwa, jeżeli amortyzatory nie są zużyte. Konieczny jest wówczas demontaż amortyzatorów. Dlatego warto wymienić sprężyny razem z amortyzatorami, jeśli ich sprawność jest widocznie obniżona. Dzięki temu nie trzeba ponosić kosztu wymiany samych amortyzatorów w niedalekiej przyszłości.
      </p>
      
      <div className='onepage-footer'>
        <h2>​Zapraszamy do serwisu Euromaster Bednarscy. Nasz zespół specjalistów jest gotowy, aby zapewnić Twojemu pojazdowi profesjonalną obsługę i dbałość o każdy szczegół. Zaufaj naszemu doświadczeniu i pozwól nam zadbać o Twój samochód.</h2>
      </div>
    </div>
    </>
  )
}