import React from 'react';
import PropTypes from 'prop-types';

export default function MainButton({ text, href = '#', onClick = () => {}, className = '' }) {
  return (
    <a
      href={href}
      target='_blank'
      rel='noopener noreferrer'
      onClick={onClick}
      className={`main-button px-14 py-4 bg-main-blue text-white font-bold text-lg rounded-sm transform transition-transform duration-300 ease-in-out ${className}`}
    >
      {text}
    </a>
  );
}

MainButton.propTypes = {
  text: PropTypes.string.isRequired,
  href: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
};