import React, { useEffect, useState } from 'react';
import { supabase } from '../../supabaseClient';
import BlogCard from '../../components/BlogCard/BlogCard';
import { Helmet } from 'react-helmet';
import './Blog.css';

const BlogWidget = () => {
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    const fetchBlogs = async () => {
      const { data, error } = await supabase
        .from('blogs')
        .select('*')
        .not('image_url', 'is', null)
        .order('created_at', { ascending: false });

      if (error) {
        console.error('Error fetching blogs:', error);
      } else {
        setBlogs(data);
      }
    };

    fetchBlogs();
  }, []);

  return (
  <>
      <Helmet>
        <title>Blog - Euromaster Bednarscy</title>
        <meta name="description" content="Najnowsze wpisy - Euromaster Bednarscy" />
        <meta property="og:title" content="Blog - Euromaster Bednarscy" />
        <meta property="og:description" content="Najnowsze wpisy - Euromaster Bednarscy" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Euromaster Bednarscy" />
      </Helmet>
      <div className="blog-widget md:px-20 px-8 py-10 grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-6 justify-center items-center">
      {blogs.map((blog) => (
        <BlogCard key={blog.id} blog={blog} />
      ))}
    </div>
  </>
);
};

export default BlogWidget;