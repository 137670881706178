import React from 'react'
import './MenuList.css'
import oponyosobowe from '../../assets/images/opony-osobowe.webp';
import oponyciezarowe from '../../assets/images/opony-ciezarowe.webp';
import ciezarowe from '../../assets/images/ciezarowe.webp';
import osobowe from '../../assets/images/osobowe.webp';
import MenuButton from '../MenuButton/MenuButton'

const menuData = [
  {
    "title": "MECHANIKA SAMOCHODÓW CIĘŻAROWYCH",
    "icon": ciezarowe,
    "link": "/mechanika-ciezarowa"
  },
  {
    "title": "MECHANIKA SAMOCHODÓW OSOBOWYCH, SUV I DOSTAWCZYCH",
    "icon": osobowe,
    "link": "/mechanika-osobowa"
  },
  {
    "title": "OPONY DO SAMOCHODÓW CIĘŻAROWYCH I NACZEP",
    "icon": oponyciezarowe,
    "link": "/opony-ciezarowe"
  },
  {
    "title": "OPONY DO SAMOCHODÓW OSOBOWYCH, SUV I DOSTAWCZYCH",
    "icon": oponyosobowe,
    "link": "/opony-osobowe"
  }
];

const MenuCard = ({ title, icon, link }) => (
  <div className='menu-card-container flex flex-col items-center py-10 px-6 bg-white shadow-lg rounded-md border-4 border-main-blue h-80 relative'>
    <div className='image-container w-16 h-16 p-2 bg-main-blue rounded-md flex items-center justify-center'>
      <img src={icon} alt={title} className='h-10 w-10 object-cover'/>
    </div>
    <h3 className='text-xl font-medium mt-3 sm:mt-6 text-center align-middle'>{title}</h3>
    <MenuButton text='WYBIERZ' href={link} className='main-button menu-button' />
  </div>
);

export default function MenuList() {
  return (
    <div className='menu-container grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-6 p-4 md:px-24 sm:px-10 px-0'>
      {menuData.map((item, index) => (
        <MenuCard 
          key={index}
          title={item.title}
          icon={item.icon}
          link={item.link}
        />
      ))}
    </div>
  )
}