import React from 'react'
import FeaturesList from '../../components/FeaturesList/FeaturesList'


export default function Features() {
  return (
    <div>
        <div className="container mx-auto px-4 py-10 flex flex-col text-center">
            <FeaturesList />
        </div>
    </div>
  )
}
