import React from 'react'
import Contact from '../../components/Kontakt/Contact'
import './Kontakt.css'
import { Helmet } from 'react-helmet';
import Map from '../../components/Map/Map'

export default function Kontakt() {
  return (
    <div>
      <Helmet>
        <title>Kontakt - Euromaster Bednarscy</title>
      </Helmet>
      <Contact />
      <div className="horizontal w-full h-5 bg-gradient-to-r from-main-blue to-main-green m-0"></div>
      <Map />
      <div className="horizontal w-full h-5 bg-gradient-to-r from-main-blue to-main-green m-0"></div>
    </div>
  )
}
