import React from 'react';
import './BlogCard.css';

const BlogCard = ({ blog }) => {
  return (
    <div className="blog-card">
      {blog.image_url && <img src={blog.image_url} alt={blog.title} loading='lazy' />}
      <p>{new Date(blog.created_at).toLocaleDateString('pl-PL', { day: '2-digit', month: '2-digit', year: 'numeric' })}</p>
      <h2>{blog.title}</h2>
      <div className='blog-content' dangerouslySetInnerHTML={{ __html: blog.content.substring(0,100) }} />
      <div className="read-more-container">
        <a href={`/blog/${blog.id}`} className="read-more-link">Czytaj dalej</a>
      </div>
    </div>
  );
};

export default BlogCard;