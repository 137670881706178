import React from 'react';

export default function Map() {
  return (
    <div>
      <iframe
        title="Google Maps"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2560.1198258182853!2d21.97912011213838!3d50.08404337140565!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x473ce5b208add17d%3A0x7b7543a1689ab771!2sEuromaster%20Bednarscy!5e0!3m2!1spl!2spl!4v1724335438724!5m2!1spl!2spl"
        width="100%"
        height="480"
        style={{ border: 0 }}
        allowFullScreen=""
        loading="lazy"
      ></iframe>
    </div>
  );
}